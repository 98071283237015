import React from "react"
import Staffing1 from "../../img/Staffing1.jpg"
import Staffing2 from "../../img/Staffing2.jpg"
import "../../styles/modal.css"

function Modal({ modalVisible, setModalVisible }) {
  // const modalVisible = props.modalVisible

  function handleClick() {
    setModalVisible(false)
  }

  return modalVisible ? (
    <div className="modal-wrap">
      <div className="modal-panel" style={{ flexDirection: "column", width: "30vw", margin: "44px 15px 0" }}>
        <div className="modal-header">
          제 11기 정기주주총회소집을 위한 기준일 및 주주명부 패쇄기간 설정공고
        </div>
        <div
          className="modal-body"
          style={{
            height: "calc(100vh - 525px)",
            overflowY: "auto",
            marginTop: 20
          }}
        >
          <div style={{ paddingTop: 20, textAlign: "center" }}>
            상법 제 354조 및 본 회사 정관 제15조에 의거하여 2025년 3월 31일 개최되는 정기주주총회에서 2024년 12월 31일 현재 주주명부에 기재되어 있는 주주에게
            <br />
            의결권을 부여하며, 권리주주의 확정을 위해 2025년 1월1일부터 2025년 1월 7일까지 주식의 명의개서, 질권의 등록 및 그 변경과 말소, 신탁재산의 표시 및 말소 등
            <br />
            주주명부의 기재사항 변경을 정지함을 공고합니다.
          </div>
          <div
            style={{
              position: "relative",
              paddingTop: 20,
              textAlign: "center",
            }}
          >
            2025년 2월 25일
            <br />
            주식회사 엠투엠테크
            <br />
            경기도 성남시 수정구 청계산로 686, 533호, 534호 (고등동, 반도 아이비밸리)
            <br />
            대표이사 김진기
          </div>
        </div>

        <div className="modal-footer">
          <div className="button-item">
            <button onClick={handleClick}>확인 (닫기)</button>
          </div>
        </div>
      </div>
      {/* <div className="modal-panel" style={{ flexDirection: "column", width: "30vw", margin: "44px 15px 0" }}>
        <div className="modal-header">
          제9기 정기주주총회소집을 위한 기준일 및 주주명부 폐쇄기간 설정공고
        </div>
        <div
          className="modal-body"
          style={{
            height: "calc(100vh - 600px)",
            overflowY: "auto",
            marginTop: 20
          }}
        >
          상법 제354조 및 본 회사 정관 제15조에 의거하여, <b>2023년 03월 31일</b>{" "}
          개최되는 임시주주총회에서 <b>2022년 12월 31일</b> 현재 주주명부에
          기재되어 있는 주주에게 의결권을 부여하며, 권리주주의 확정을 위해{" "}
          <b>2023년 1월 1일부터 2023년 1월 7일까지</b> 주식의 명의개서, 질권의
          등록 및 그 변경과 말소, 신탁재산의 표시 및 말소 등 주주명부의 기재사항
          변경을 정지함을 공고합니다.
          <br />
          <div style={{ paddingTop: 20, textAlign: "center" }}>
            2022년 3월 7일
          </div>
          <div
            style={{
              position: "relative",
              paddingTop: 20,
              textAlign: "center",
            }}
          >
            <b>주식회사 엠투엠테크</b> <br />
            <p style={{ paddingTop: 20, textAlign: "center" }}>
              <b>대표이사 김진기</b>(인)
            </p>
            <p className="signImgWrap">
              <img src={sign} alt="" />
            </p>
          </div>
        </div>

        <div className="modal-footer">
          <div className="button-item">
            <button onClick={handleClick}>확인 (닫기)</button>
          </div>
        </div>
      </div> */}
    </div>
  ) : (
    ""
  )
}

export default Modal
