import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/layout/seo"

import Visual from "../components/index/Visual"
//import IngCover from "../components/ingCover"
import "../styles/App.css"
import Modal from "./modal/Modal"

export default function IndexPage() {
  const [modalVisible, setModalVisible] = useState(true)
  const data = { modalVisible, setModalVisible }

  // var meta = document.createElement('meta');
  // meta.httpEquiv = "X-UA-Compatible";
  // meta.content = "IE=edge";
  // document.getElementsByTagName('head')[0].appendChild(meta);

  if (typeof window !== "undefined") {
    window.__setModalVisible = setModalVisible;
  }

  return (
    <Layout>
      <SEO title="M2MTech" />
      <Visual />
      <Modal {...data} />
      {/*<IngCover />*/}
    </Layout>
  )
}
